.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .projects h4 {
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .projects p {
  color: #666;
}
.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}
textarea {
  width: 100%;
}
.list-group-item-heading {
  text-align: left;
  font-weight: normal;
}
.btn:focus,
.list-group-item:focus {
  outline: none;
}
